import React from 'react';
import styled from 'styled-components';
import { Boom, Wrapper, Navbar, MobileMenu, Footer, Spacer } from './Elements';
import bluebg from '../img/blue-bg.png';
import cellbgbottomright from '../img/cellbg-bottomright.png';
import NatureBiotechnology from '../pdf/NatureBiotechnology.pdf';
import Protocol from '../pdf/Protocol.pdf';
import Gastroenterology from '../pdf/Gastroenterology.pdf';
import PLOSONE from '../pdf/PLOS-ONE.pdf';

const PubHead = styled.div`
    background-image: url(${bluebg});
    background-size: cover;
    background-position: 50% 50%;
    height: 229px;
    padding-bottom: 4rem;
    padding-top: 60px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    text-align: center;
`;

const PubHeadWrapWrap = styled.div`
    width: 100%;
    max-width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    display: block;
`;

const PubHeadWrap = styled.div`
    float: none;
    margin: 0 auto;
    position: relative;
    width: 100%;
    min-height: 1px;
    display: block;
`;

const SubHeadline = styled.h2`
    font-size: 14px;
    color: #7AC3FF;
    text-transform: uppercase;
    font-family: "Lato", sans-serif;
    font-weight: 900;
    letter-spacing: 2.75px;
    margin-bottom: 1rem;
    line-height: 1.1;
    margin-top: 0;
`;

const Headline = styled.h1`
    font-size: 33px;
    line-height: 1.4;
    font-family: 'Signika', sans-serif;
    color:#ffffff;
`;

const Pubs = styled.div`
    background-color: #F6F7F8;
    background-image: url(${cellbgbottomright});
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 50%;
    padding-top: 5rem;
    padding-bottom: 5rem;
    display: block;
`;

const PubsContainer = styled.div`
    width: 1140px;
    max-width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
`;

const PubInstructions = styled.div`
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-right: -15px;
    margin-left: -15px;
    display: flex;
`;

const InsideInstructions = styled.div`
    float: none;
    margin: 0 auto;
    flex: 0 0 50%;
    max-width: 50%;
    -webkit-box-flex: 0;
    position: relative;
    width: 100%;
    min-height: 1px;
`;

const Instructions = styled.p`
    width:75%;
    opacity: 0.42;
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-size: 23px;
    color: #000B0B;
    letter-spacing: 0.19px;
    line-height: 1.4;
    margin-bottom: 0;
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    text-align:center;
`;

const GridHolder  = styled.div`
    margin-top:90px;
    border-bottom: 2px solid #E9EAEB;
    border-top: 2px solid #E9EAEB;
    display: flex;
    flex-wrap: wrap;
    margin-left:auto;
    margin-right:auto;
    width:85%;
`;

const GridLeft = styled.div`
    box-sizing: border-box;
    padding-right: 0;
    border-right: 2px solid #E9EAEB;
    border-bottom: 2px solid #E9EAEB;
    margin-bottom: 0;
    padding-left: 0;
    -webkit-box-flex: 0;
    position: relative;
    width: 100%;
    min-height: 1px;
    @media (min-width: 768px) {
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%;
    }
    &:hover {
        background-color:#ffffff;
    }
`;

const GridRight = styled.div`
    box-sizing: border-box;
    border-bottom: 2px solid #E9EAEB;
    margin-bottom: 0;
    padding-left: 0;
    @media (min-width: 768px) {
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%;
    }
    @media (min-width: 1200px) {
        padding-right: 15px;
    }
    position: relative;
    width: 100%;
    min-height: 1px;
    &:hover {
        background-color:#ffffff;
    }
`;

const ArticleHolder = styled.div`
    display: block;
    height: 100%;
    padding: 40px;
    cursor: pointer;
    text-decoration: none;
    color: #0275d8;
    background-color: transparent;
    
`;

const ArtIcon = styled.div`
    display:inline-block;
    vertical-align:middle;
`;

class ArticleIcon extends React.Component {
    render() {
        return(
            <ArtIcon>
            <svg width="20px" height="24px" viewBox="0 0 20 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <defs />
                <g id="Full-Design" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                  <g id="Resources---Publications" transform="translate(-782.000000, -600.000000)" stroke="#D80000" strokeWidth="0.1" fill="#D80000" fillRule="nonzero">
                    <g id="Group-4" transform="translate(783.000000, 601.000000)">
                      <path d="M12.3924978,0.733335686 L11.8749979,0.163780193 C11.7797566,0.059192777 11.6432184,-0.00043899837 11.499998,2.43355884e-06 L1.99999965,2.43355884e-06 C0.895430343,2.43355884e-06 0,0.875534381 0,1.95555777 L0,20.0444447 C0,21.1244681 0.895430343,22 1.99999965,22 L15.9999972,22 C17.1045665,22 17.9999968,21.1244681 17.9999968,20.0444447 L17.9999968,7.08889054 C18.000432,6.96921337 17.9559563,6.85353715 17.8749969,6.76377946 L12.4999978,0.85311345 L12.4999978,0.733335686 L12.3924978,0.733335686 Z M16.999997,20.0444447 C16.999997,20.5844564 16.5522818,21.0222223 15.9999972,21.0222223 L1.99999965,21.0222223 C1.447715,21.0222223 0.999999824,20.5844564 0.999999824,20.0444447 L0.999999824,1.95555777 C0.999999824,1.41554608 1.447715,0.977780103 1.99999965,0.977780103 L11.274998,0.977780103 L11.499998,1.22222452 L11.499998,5.86666845 C11.499998,6.54168307 12.0596419,7.08889054 12.7499978,7.08889054 L16.829997,7.08889054 L16.999997,7.2746683 L16.999997,20.0444447 Z M15.9424972,6.11111287 L12.7499978,6.11111287 C12.6119266,6.11111287 12.4999978,6.00167138 12.4999978,5.86666845 L12.4999978,2.32466884 L15.9424972,6.11111287 Z" id="Shape" />
                      <path d="M13.8857143,10.56 L4.11428571,10.56 C3.83025356,10.56 3.6,10.7569947 3.6,11 C3.6,11.2430053 3.83025356,11.44 4.11428571,11.44 L13.8857143,11.44 C14.1697464,11.44 14.4,11.2430053 14.4,11 C14.4,10.7569947 14.1697464,10.56 13.8857143,10.56 Z" id="Shape" />
                      <path d="M13.8857143,14.08 L4.11428571,14.08 C3.83025356,14.08 3.6,14.2769947 3.6,14.52 C3.6,14.7630053 3.83025356,14.96 4.11428571,14.96 L13.8857143,14.96 C14.1697464,14.96 14.4,14.7630053 14.4,14.52 C14.4,14.2769947 14.1697464,14.08 13.8857143,14.08 Z" id="Shape" />
                      <path d="M13.8857143,17.6 L4.11428571,17.6 C3.83025356,17.6 3.6,17.7969947 3.6,18.04 C3.6,18.2830053 3.83025356,18.48 4.11428571,18.48 L13.8857143,18.48 C14.1697464,18.48 14.4,18.2830053 14.4,18.04 C14.4,17.7969947 14.1697464,17.6 13.8857143,17.6 Z" id="Shape" />
                      <path d="M4.09090909,7.04 L8.50909091,7.04 C8.78021251,7.04 9,6.84300529 9,6.6 C9,6.35699471 8.78021251,6.16 8.50909091,6.16 L4.09090909,6.16 C3.81978749,6.16 3.6,6.35699471 3.6,6.6 C3.6,6.84300529 3.81978749,7.04 4.09090909,7.04 Z" id="Shape" />
                    </g>
                  </g>
                </g>
              </svg>
            </ArtIcon>
        );
    }
}

const ArticleTopLine = styled.div`
`;

const ArticleCat = styled.h5`
    padding-top:7px;
    padding-left:7px;
    display: inline-block;
    vertical-align:middle;
    font-family: "Lato", sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: #D80000;
    letter-spacing: 0;
    margin: 0 0 10px;
`;

const ArticleDate = styled.span`
    color: rgba(0, 4, 11, 0.2);
    margin-left: 5px;
    box-sizing: inherit;
    font-family: "Lato", sans-serif;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0;
`;

const ArticleTitle = styled.h3`
    font-family: "Signika", serif;
    font-size: 23px;
    color: #00040B;
    letter-spacing: 0;
    line-height: 1.3;
    margin: 0 0 1rem;
    font-weight: 500;
`;

const ArticleText = styled.p`
    margin-bottom: 0;
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #242D2D;
    letter-spacing: 0.14px;
    line-height: 1.6;
    margin-top: 0;   
`;

const ArticleBody = styled.p`
    font-size: 14px;
    margin-bottom: 1rem;
    box-sizing: border-box;
    font-family: "Lato", sans-serif;
    font-weight: 400;
    color: #242D2D;
    letter-spacing: 0.14px;
    line-height: 1.6;
    margin-top: 0;
    text-align: left;
    cursor: auto;
    white-space: normal;
`;

const ArticleDownload = styled.a`
    background: #D80000;
    color: #fff;
    text-transform: uppercase;
    font-family: "Lato", sans-serif;
    font-weight: 700;
    font-size: 11.5px;
    letter-spacing: 2.25px;
    padding: 14px 22px;
    border-radius: 2px;
    margin-top: 1rem;
    border: none;
    box-sizing: border-box;
    display: inline-block;
    line-height: 1.25;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    transition: all .2s ease-in-out;
    touch-action: manipulation;
    text-decoration: none;
    cursor: pointer;
`;

// PAPER COMPONENTS

class Article1 extends React.Component {
    modalDownload = (download) => {
        window.open(download, '_blank');
    }
    render() {
        return (
            <>
            <div>
                <ArticleBody>
                    Severe acute liver failure, even when transient, must be treated by transplantation and lifelong immune suppression. Treatment
could be improved by bioartificial liver (BAL) support, but this approach is hindered by a shortage of human hepatocytes. To
generate an alternative source of cells for BAL support, we differentiated mouse embryonic stem (ES) cells into hepatocytes
by coculture with a combination of human liver nonparenchymal cell lines and fibroblast growth factor-2, human activin A&nbsp;and hepatocyte growth factor. Functional hepatocytes were isolated using albumin promoter–based cell sorting. ES cell–derived
hepatocytes expressed liver-specific genes, secreted albumin and metabolized ammonia, lidocaine and diazepam. Treatment
of 90% hepatectomized mice with a subcutaneously implanted BAL seeded with ES cell–derived hepatocytes or primary
hepatocytes improved liver function and prolonged survival, whereas treatment with a BAL seeded with control cells&nbsp;did not. After functioning in the BAL, ES cell–derived hepatocytes developed characteristics nearly identical to those of
primary hepatocytes.&nbsp;
                </ArticleBody>
            </div>
            <ArticleDownload href="#" onClick={() => this.modalDownload(NatureBiotechnology)}>Download Paper</ArticleDownload>
            </>
        );
    }
}

class Article2 extends React.Component {
    modalDownload = (download) => {
        window.open(download, '_blank');
    }
    render() {
        return (
            <>
            <div>
                <ArticleBody>
                    This protocol describes a co-culture system for the in vitro differentiation of mouse embryonic stem cells into hepatocyte-like cells.
Differentiation involves four steps: (i) formation of embryoid bodies (EB), (ii) induction of definitive endoderm from 2-d-old EBs,
(iii) induction of hepatic progenitor cells and (iv) maturation into hepatocyte-like cells. Differentiation is completed by 16 d of
culture. EBs are formed, and cells can be induced to differentiate into definitive endoderm by culture in Activin A and fibroblast
growth factor 2 (FGF-2). Hepatic differentiation and maturation of cells is accomplished by withdrawal of Activin A and FGF-2&nbsp;and by exposure to liver nonparenchymal cell-derived growth factors, a deleted variant of hepatocyte growth factor (dHGF) and
dexamethasone. Approximately 70% of differentiated embryonic stem cells express albumin and can be recovered by albumin
promoter-based cell sorting. The sorted cells produce albumin in culture and metabolize ammonia, lidocaine and diazepam at
approximately two-thirds the rate of primary mouse hepatocytes.&nbsp;
                </ArticleBody>
            </div>
            <ArticleDownload  href="#" onClick={() => this.modalDownload(Protocol)}>Download Paper</ArticleDownload>
            </>
        );
    }
}

class Article3 extends React.Component {
    modalDownload = (download) => {
        window.open(download, '_blank');
    }
    render() {
        return (
            <>
            <div>
                <ArticleBody>
                    <strong>Background &amp; Aims:</strong>
                     The ability to obtain unlimited numbers of human hepatocytes would improve the development of cell-based therapies for liver diseases, facilitate the study of liver biology, and improve the early stages of drug discovery. Embryonic stem cells are pluripotent, potentially can differentiate into any cell type, and therefore could be developed as a source of human hepatocytes. 
                    <strong>Methods:</strong>
                     To generate human hepatocytes, human embryonic stem cells were differentiated by sequential culture in fibroblast growth factor 2 and human activin-A, hepatocyte growth factor, and dexamethasone. Functional hepatocytes were isolated by sorting for surface asialoglycoprotein-receptor expression. Characterization was performed by real-time polymerase chain reaction, immunohistochemistry, immunoblot, functional assays, and transplantation. 
                    <strong>Results: </strong>
                    Embryonic stem cell–de- rived hepatocytes expressed liver-specific genes, but not genes representing other lineages, secreted functional human liver–specific proteins similar to those of primary human hepatocytes, and showed human hepatocyte cytochrome P450 metabolic activity. Serum from rodents given injections of embryonic stem cell–derived hepatocytes contained significant amounts of human albumin and 1-antitrypsin. Colonies of cytokeratin-18 and human albumin– express- ing cells were present in the livers of recipient animals. 
                    <strong>Conclusions:</strong>
                     Human embryonic stem cells can be differentiated into cells with many characteristics of primary human hepatocytes. Hepatocyte-like cells can be enriched and recovered based on asialoglycoprotein-receptor expression and potentially could be used in drug discovery research and developed as therapeutics. &nbsp;
                </ArticleBody>
            </div>
            <ArticleDownload href="#" onClick={() => this.modalDownload(Gastroenterology)}>Download Paper</ArticleDownload>
            </>
        );
    }
}

class Article4 extends React.Component {
    modalDownload = (download) => {
        window.open(download, '_blank');
    }
    render() {
        return (
            <>
            <div>
                <ArticleBody>
                    <strong>Background:</strong>
                     Although the rat is extensively used as a laboratory model, the inability to utilize germ line-competent rat
embryonic stem cells has been a major drawback for studies that aim to elucidate gene functions. Recently, zinc-finger
nucleases (ZFNs) were successfully used to create genome-specific double-stranded breaks and thereby induce targeted
gene mutations in a wide variety of organisms including plants, drosophila, zebrafish, etc.
                    <strong>Methodology/Principal Findings:</strong>
                     We report here on ZFN-induced gene targeting of the rat interleukin 2 receptor gamma (Il2rg)
locus, where orthologous human and mouse mutations cause X-linked severe combined immune deficiency (X-SCID). Co-injection
of mRNAs encoding custom-designed ZFNs into the pronucleus of fertilized oocytes yielded genetically modified offspring at rates
greater than 20%, which possessed a wide variety of deletion/insertion mutations. ZFN-modified founders faithfully transmitted
their genetic changes to the next generation along with the severe combined immune deficiency phenotype.&nbsp;
                    <strong>Conclusions and Significance:</strong>
                     The efficient and rapid generation of gene knockout rats shows that using ZFN technology is
a new strategy for creating gene-targeted rat models of human diseases. In addition, the X-SCID rats that were established
in this study will be valuable in vivo tools for evaluating drug treatment or gene therapy as well as model systems for
examining the treatment of xenotransplanted malignancies.&nbsp;
                </ArticleBody>
            </div>
            <ArticleDownload href="#" onClick={() => this.modalDownload(PLOSONE)}>Download Paper</ArticleDownload>
            </>
        );
    }
}

class Article5 extends React.Component {
    modalDownload = (download) => {
        window.open(download, '_blank');
    }
    render() {
        return (
            <>
            <div>
                <ArticleBody>
                    Severe combined immunodeficiency (SCID) mice, the most widely used animal model of DNA-PKcs (Prkdc) deficiency, have contributed enormously to our understanding of immunodeficiency, lymphocyte development, and DNA-repair mechanisms, and they are ideal hosts for allogeneic and xenogeneic tissue transplantation. Here, we use zinc-finger nucleases to generate rats that lack either the Prkdc gene (SCID) or the Prkdc and Il2rg genes (referred to as F344-scid gamma [FSG] rats). SCID rats show several phenotypic differences from SCID mice, including growth retardation, premature senescence, and a more severe immunodeficiency without ‘‘leaky’’ phenotypes. Double-knockout FSG rats show an even more immunocompromised phenotype, such as the abolishment of natural killer cells. Finally, xenotransplantation of human induced pluripotent stem cells, ovarian cancer cells, and hepatocytes shows that SCID and FSG rats can act as hosts for xenogeneic tissue grafts and stem cell transplantation and may be useful for preclinical testing of new drugs.
                </ArticleBody>
            </div>
            <ArticleDownload href="#" onClick={() => this.modalDownload("http://www.cell.com/cell-reports/pdf/S2211-1247(12)00234-3.pdf")}>Download Paper</ArticleDownload>
            </>
        );
    }
}

class Article6 extends React.Component {
    modalDownload = (download) => {
        window.open(download, '_blank');
    }
    render() {
        return (
            <>
            <div>
                <ArticleBody>
                    The CRISPR-Cas system is a powerful tool for generating genetically modified animals; however, targeted knock-in (KI) via homologous recombination remains difficult in zygotes. Here we show efficient gene KI in rats by combining CRISPR-Cas with single-stranded oligodeoxynucleotides (ssODNs). First, a 1-kb ssODN co-injected with guide RNA (gRNA) and Cas9 messenger RNA produce GFP-KI at the rat 
                    <em>Thy1</em>
                     locus. Then, two gRNAs with two 80-bp ssODNs direct efficient integration of a 5.5-kb CAG-GFP vector into the 
                    <em>Rosa26</em>
                     locus via ssODN-mediated end joining. This protocol also achieves KI of a 200-kb BAC containing the human 
                    <em>SIRPA</em>
                     locus, concomitantly knocking out the rat Sirpa gene. Finally, three gRNAs and two ssODNs replace 58-kb of the rat 
                    <em>Cyp2d</em>
                     cluster with a 6.2-kb human 
                    <em>CYP2D6</em>
                     gene. These ssODN-mediated KI protocols can be applied to any target site with any donor vector without the need to construct homology arms, thus simplifying genome engineering in living organisms.
                </ArticleBody>
            </div>
            <ArticleDownload href="#" onClick={() => this.modalDownload("https://www.nature.com/articles/ncomms10431.pdf")}>Download Paper</ArticleDownload>
            </>
        );
    }
}

class Article7 extends React.Component {
    modalDownload = (download) => {
        window.open(download, '_blank');
    }
    render() {
        return (
            <>
            <div>
                <ArticleBody>
                    <strong>Background &amp; Aims:</strong>
                     Hepatocyte transplantation partially corrects genetic disorders and has been associated anecdotally with reversal of acute liver failure. Monitoring for graft function and rejection has been difficult, and has contributed to limited graft survival. Here we aimed to use preparative liver-directed radiation therapy, and continuous monitoring for possible rejection in an attempt to overcome these limitations.&nbsp;
                    <strong>Methods:</strong>
                     Preparative hepatic irradiation was examined in non- human primates as a strategy to improve engraftment of donor hepatocytes, and was then applied in human subjects. T cell immune monitoring was also examined in human subjects to assess adequacy of immunosuppression.&nbsp;
                    <strong>Results:</strong>
                     Porcine hepatocyte transplants engrafted and expanded to comprise up to 15% of irradiated segments in immunosuppressed monkeys preconditioned with 10 Gy liver-directed irradiation. Two patients with urea cycle deficiencies had early graft loss following hepatocyte transplantation; retrospective immune monitoring suggested the need for additional immunosuppression. Preparative radiation, anti-lymphocyte induction, and frequent immune monitoring were instituted for hepatocyte transplantation in a 27 year old female with classical phenylketonuria. Post-transplant liver biopsies demonstrated multiple small clusters of transplanted cells, multiple mitoses, and Ki67+ hepatocytes. Mean peripheral blood phenylalanine (PHE) level fell from pre-transplant levels of 1343±48lM (normal 30– 119 lM) to 854 ± 25 lM (treatment goal 6360 lM) after trans- plant (36% decrease; p &lt;0.0001), despite transplantation of only half the target number of donor hepatocytes. PHE levels remained below 900lM during supervised follow-up, but graft loss occurred after follow-up became inconsistent.<Spacer />
                    <strong>Conclusions:</strong>
                     Radiation preconditioning and serial rejection risk assessment may produce better engraftment and long-term survival of transplanted hepatocytes. Hepatocyte xenografts engraft for a period of months in non-human primates and may provide effective therapy for patients with acute liver failure.
                </ArticleBody>
            </div>
            <ArticleDownload href="#" onClick={() => this.modalDownload("http://www.journal-of-hepatology.eu/article/S0168-8278(16)30750-4/pdf")}>Download Paper</ArticleDownload>
            </>
        );
    }
}

const Modal = styled.div`
    display:${props => props.display};
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2147483647;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    background: rgba(0,0,0,.8);
    -webkit-tap-highlight-color: transparent;
    :before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }
`;

const ModalInner = styled.div`
    position: relative;
    text-align: left;
    vertical-align: middle;
    display: inline-block;
    overflow: auto;
    padding: 25px 25px 0;
    border-bottom: 25px solid transparent;
    max-height: 95%;
    background: #fff;
    cursor: auto;
    white-space: normal;
    box-sizing: border-box;
    @media screen and (min-width: 1200px) {
        margin-left: 27% !important;
        margin-right: 27% !important;
    }
`;

const ModalClose = styled.button`
    position: absolute;
    z-index: 9999;
    top: 0;
    right: 0;
    line-height: 25px;
    width: 25px;
    cursor: pointer;
    text-align: center;
    font-family: arial,sans-serif;
    background: rgba(255,255,255,.3);
    color: #000;
    border: 0;
    padding: 0;
    box-sizing: border-box;
    touch-action: manipulation;
    -webkit-appearance: button;
    text-transform: none;
    overflow: visible;
    margin: 0;
    white-space: normal;
`;

const ModalArticle = styled.div`
    display: block;
    padding: 25px;
    box-sizing: border-box;
    text-align: left;
    cursor: auto;
    white-space: normal; 
`;

class ArticleModal extends React.Component {
    render() {
        var display = {};
        if (this.props.display !== undefined) {
            display['display'] = this.props.display;
        }
        return(
            <Modal {...display} onClick={this.props.modalDown}>
                <ModalInner onClick={this.props.childClick}>
                    <ModalClose onClick={this.props.modalDown}>X</ModalClose>
                    <ModalArticle>
                        <ArticleTopLine>
                            <ArticleIcon /><ArticleCat>{this.props.cat} <ArticleDate>{this.props.date}</ArticleDate></ArticleCat>
                        </ArticleTopLine>
                        <ArticleTitle>{this.props.title}</ArticleTitle>
                        {this.props.body}
                    </ModalArticle>
                </ModalInner>
            </Modal>
        );
    }
}

class Publications extends React.Component {
    state = {
        mobile_display:"none",
        wrapper_margin:"0px",
        resources_ddd:"none",
        tech_ddd:"none",
        modal_display:"none",
        modal_cat:"",
        modal_date:"",
        modal_title:"",
        modal_body:"",
    }
    mobileMenu = () => {
        var data = this;
        if (data.state.mobile_display === "none") {
            data.setState({
                mobile_display:"block"
            }, () => setTimeout(data.setState({wrapper_margin:"-320px"}), 1000));
        } else {
            data.setState({
                wrapper_margin:"0px"
            }, () => setTimeout(data.setState({mobile_display:"none"}), 1000));
        }
    }
    resourcesDD = () => {
        var data = this;
        if (data.state.resources_ddd === "none") {
            if (data.state.tech_ddd === "block") {
                data.setState({tech_ddd:"none"});
            }
            data.setState({resources_ddd:"block"});
        } else {
            data.setState({resources_ddd:"none"});
        }
    }
    techDD = () => {
        var data = this;
        if (data.state.tech_ddd === "none") {
            if (data.state.resources_ddd === "block") {
                data.setState({resources_ddd:"none"});
            }
            data.setState({tech_ddd:"block"});
        } else {
            data.setState({tech_ddd:"none"});
        }
    }
    modalUp = (cat, date, title, body) => {
        var data = this;
        data.setState({
            modal_cat:cat,
            modal_date:date,
            modal_title:title,
            modal_body:body
        }, () => data.setState({modal_display:"inherit"}));
    }
    modalDown = (event) => {
        event.preventDefault();
        if (event.target === event.currentTarget) {
            var data = this;
            data.setState({
                modal_display:"none",
                modal_cat:"",
                modal_date:"",
                modal_title:"",
                modal_body:""
            });
        }
    }
    childddClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
    }
    render() {
        if (this.state.mobile_display) {
            return (
                <Boom>
                    <ArticleModal 
                        childClick={this.childClick} 
                        modalDown={this.modalDown} 
                        display={this.state.modal_display} 
                        cat={this.state.modal_cat} 
                        date={this.state.modal_date} 
                        title={this.state.modal_title} 
                        body={this.state.modal_body} />
                    <MobileMenu display={this.state.mobile_display} />
                    <Wrapper margin={this.state.wrapper_margin}>
                        <Navbar {...this.props} mobileMenu={this.mobileMenu} techDD={this.techDD} tech_display={this.state.tech_ddd} resourcesDD={this.resourcesDD} display={this.state.resources_ddd} />
                        <PubHead>
                            <PubHeadWrapWrap>
                                <PubHeadWrap>
                                    <SubHeadline>Resources</SubHeadline>
                                    <Headline>Recent Publications</Headline>
                                </PubHeadWrap>
                            </PubHeadWrapWrap>
                        </PubHead>
                        <Pubs>
                            <PubsContainer>
                                <PubInstructions>
                                    <InsideInstructions>
                                        <Instructions>Click on a paper's title to view the abstract and/or download a PDF of the full paper.</Instructions>
                                    </InsideInstructions>
                                </PubInstructions>
                            </PubsContainer>
                            <GridHolder>
                                <GridLeft onClick={() => this.modalUp("Nature Biotechnology", "Nov 2006", "Reversal of mouse hepatic failure using an implanted liver-assist device containing ES cell-derived hepatocytes", <Article1 />)}>
                                    <ArticleHolder>
                                        <ArticleTopLine>
                                            <ArticleIcon /><ArticleCat>Nature Biotechnology <ArticleDate>Nov 2006</ArticleDate></ArticleCat>
                                        </ArticleTopLine>
                                        <ArticleTitle>Reversal of mouse hepatic failure using an implanted liver-assist device containing ES cell-derived hepatocytes</ArticleTitle>
                                        <ArticleText>
                                            Severe acute liver failure, even when transient, must be treated by transplantation and lifelong immune suppression. Treatment
could be improved by bioartificial liver (BAL) support, but this approach is hindered by a shortage of human...
                                        </ArticleText>
                                    </ArticleHolder>
                                </GridLeft>
                                <GridRight onClick={() => this.modalUp("Protocol", "Feb 2007", "Differentiation of mouse embryonic stem cells to hepatocyte-like cells by co-culture with human liver nonparenchymal cell lines", <Article2 />)}>
                                    <ArticleHolder>
                                        <ArticleTopLine>
                                            <ArticleIcon /><ArticleCat>Protocol <ArticleDate>Feb 2007</ArticleDate></ArticleCat>
                                        </ArticleTopLine>
                                        <ArticleTitle>Differentiation of mouse embryonic stem cells to hepatocyte-like cells by co-culture with human liver nonparenchymal cell lines</ArticleTitle>
                                        <ArticleText>
                                            This protocol describes a co-culture system for the in vitro differentiation of mouse embryonic stem cells into hepatocyte-like cells.
Differentiation involves four steps: (i) formation of embryoid bodies (EB), (ii) induction of defini...
                                        </ArticleText>
                                    </ArticleHolder>
                                </GridRight>
                                <GridLeft onClick={() => this.modalUp("Gastroenterology", "Mar 2009", "Differentiation and Transplantation of Human Embryonic Stem Cell–Derived Hepatocytes", <Article3 />)}>
                                    <ArticleHolder>
                                        <ArticleTopLine>
                                            <ArticleIcon /><ArticleCat>Gastroenterology <ArticleDate>Mar 2009</ArticleDate></ArticleCat>
                                        </ArticleTopLine>
                                        <ArticleTitle>Differentiation and Transplantation of Human Embryonic Stem Cell–Derived Hepatocytes</ArticleTitle>
                                        <ArticleText>
                                            Background &amp; Aims: The ability to obtain unlimited numbers of human hepatocytes would improve the development of cell-based therapies for liver diseases, facilitate the study of liver biology, and improve the early stages of drug disco...
                                        </ArticleText>
                                    </ArticleHolder>
                                </GridLeft>
                                <GridRight onClick={() => this.modalUp("PLOS ONE", "Jan 2010", "Generation of Knockout Rats with X-Linked Severe Combined Immunodeficiency (X-SCID) Using Zinc-Finger Nucleases", <Article4 />)}>
                                    <ArticleHolder>
                                        <ArticleTopLine>
                                            <ArticleIcon /><ArticleCat>PLOS ONE <ArticleDate>Jan 2010</ArticleDate></ArticleCat>
                                        </ArticleTopLine>
                                        <ArticleTitle>Generation of Knockout Rats with X-Linked Severe Combined Immunodeficiency (X-SCID) Using Zinc-Finger Nucleases</ArticleTitle>
                                        <ArticleText>
                                            Background: Although the rat is extensively used as a laboratory model, the inability to utilize germ line-competent rat
embryonic stem cells has been a major drawback for studies that aim to elucidate gene functions. Recently, zinc-fi...
                                        </ArticleText>
                                    </ArticleHolder>
                                </GridRight>
                                <GridLeft onClick={() => this.modalUp("Cell Reports", "September 2012", "Generation and Characterization of Severe Combined Immunodeficiency Rats", <Article5 />)}>
                                    <ArticleHolder>
                                        <ArticleTopLine>
                                            <ArticleIcon /><ArticleCat>Cell Reports <ArticleDate>September 2012</ArticleDate></ArticleCat>
                                        </ArticleTopLine>
                                        <ArticleTitle>Generation and Characterization of Severe Combined Immunodeficiency Rats</ArticleTitle>
                                        <ArticleText>
                                            Severe combined immunodeficiency (SCID) mice, the most widely used animal model of DNA-PKcs (Prkdc) deficiency, have contributed enormously to our understanding of immunodeficiency, lymphocyte development, and DNA-repair mechanisms, an...
                                        </ArticleText>
                                    </ArticleHolder>
                                </GridLeft>
                                <GridRight onClick={() => this.modalUp("Nature Communications", "January 2016", "ssODN-mediated knock-in with CRISPR-Cas for large genomic regions in zygotes", <Article6 />)}>
                                    <ArticleHolder>
                                        <ArticleTopLine>
                                            <ArticleIcon /><ArticleCat>Nature Communications <ArticleDate>January 2016</ArticleDate></ArticleCat>
                                        </ArticleTopLine>
                                        <ArticleTitle>ssODN-mediated knock-in with CRISPR-Cas for large genomic regions in zygotes</ArticleTitle>
                                        <ArticleText>
                                            The CRISPR-Cas system is a powerful tool for generating genetically modified animals; however, targeted knock-in (KI) via homologous recombination remains difficult in zygotes. Here we show efficient gene KI in rats by combining CRISPR...
                                        </ArticleText>
                                    </ArticleHolder>
                                </GridRight>
                                <GridLeft onClick={() => this.modalUp("EASL Journal of Hepatology", "May 2017", "Host conditioning and rejection monitoring in hepatocyte transplantation in humans", <Article7 />)}>
                                    <ArticleHolder>
                                        <ArticleTopLine>
                                            <ArticleIcon /><ArticleCat>EASL Journal of Hepatology <ArticleDate>May 2017</ArticleDate></ArticleCat>
                                        </ArticleTopLine>
                                        <ArticleTitle>Host conditioning and rejection monitoring in hepatocyte transplantation in humans</ArticleTitle>
                                        <ArticleText>
                                            Background &amp; Aims: Hepatocyte transplantation partially corrects genetic disorders and has been associated anecdotally with reversal of acute liver failure. Monitoring for graft function and rejection has been difficult, and has contri...
                                        </ArticleText>
                                    </ArticleHolder>
                                </GridLeft>
                            </GridHolder>
                        </Pubs>
                        <Footer  />
                    </Wrapper>
                </Boom>
            );
        } else {
            return (
                <div>&nbsp;</div>
            );
        }
    }
}

export {Publications}
