import React from 'react'; 
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import logo from '../img/vbw-light-on-dark.png';
import logo2 from '../img/lightlogo.png';

const Boom = styled.div`
    position:relative;
    width:100%;
`;

const Wrapper = styled.div`
    position:relative;
    width:100%;
    background-color: ${props => props.grey ? '#eeeeee' : '#ffffff'};
    margin-left:${props => props.margin};
    transition: margin-left 300ms;
`;

const Header = styled.div`
    position: absolute;
    height: 75px;
    padding: 0 30px;
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width:calc(100% - 50px);
    z-index:2;
`;

const Logo = styled.img`
    opacity:1;
    width: 162px;
    position: relative;
    bottom: 2px;
    max-width: 100%;
    vertical-align: middle;
    border-style: none;
`;

const Nav = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: block;
    @media (max-width: 1180px) {
        display:none;
    }
`;

const NavItem = styled.li`
    display: inline-block;
    margin: 0 1rem;
`;

const NavLink = styled(Link)`
    font-family: "Lato", sans-serif;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 2.02px;
    text-decoration: none !important;
    color: #FFFFFF;
    text-shadow: 0 0 22px #000B0B;
    background-color: transparent;
    font-weight:bold;
`;

const DropDown = styled.div`
    margin-top: -3px;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .3em;
    vertical-align: middle;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-left: .3em solid transparent;
`;

const DropDownMenu = styled.div`
    display: ${props => props.display};
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    border-radius: 2px;
    position: absolute;
    top: 50px;
    right: 73px;
    z-index: 1000;
    float: left;
    min-width: 10rem;
    padding: .5rem 0;
    margin: .125rem 0 0;
    font-size: 1rem;
    color: #292b2c;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
`;

const TDDropDownMenu = styled.div`
    display: ${props => props.tech_display};
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    border-radius: 2px;
    position: absolute;
    top: 50px;
    right: 163px;
    z-index: 1000;
    float: left;
    min-width: 10rem;
    padding: .5rem 0;
    margin: .125rem 0 0;
    font-size: 1rem;
    color: #292b2c;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
`;

const DropDownLink = styled(Link)`
    color: #00040B;
    padding: 3px 1rem;
    color: #00040B;
    display: block;
    width: 100%;
    clear: both;
    font-weight: 400;
    text-align: inherit;
    white-space: nowrap;
    background: 0 0;
    border: 0;
    font-family: "Lato", sans-serif;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 2.02px;
    text-decoration: none !important;
`;

const FooterWrap = styled.div`
    height: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-right: 15px;
    padding-left: 15px;
    width: 93%;
    max-width: 100%;
`;

const FootLogo = styled(Link)`
`;

const FootNav = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;
`;

const FootNavItem = styled.li`
    display: inline-block;
    @media (max-width: 1285px) {
        display:block;
        white-space:nowrap;
    }
`;

const FootNavLink = styled(Link)`
    margin-right: 0;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    margin: 0 18px;
    font-size: 11px;
    color: #000B0B;
    letter-spacing: 1.5px;
    line-height: 32px;
    text-decoration:none;
    &:hover {
        text-decoration:underline;
    }
`;

const Copy =  styled.div`
    text-align: right;
    opacity: 0.33;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 10px;
    color: #000B0B;
    letter-spacing: 0.83px;
    line-height: 20px;
    margin-top: 0;
    margin-bottom: 0;
    display: block;
    @media (max-width: 1260px) {
        text-align:left;
        line-height:10px;
    }
`;

const BurgerButton = styled.button`
    display: none;
    cursor: pointer;
    user-select: none;
    padding: 25px;
    background: none;
    border: none;
    position: absolute;
    top: 0;
    right: 14px;
    -webkit-appearance: button;
    line-height: inherit;
    text-transform: none;
    touch-action: manipulation;
    overflow: visible;
    font-family: sans-serif;
    font-size: 100%;
    margin: 0;
    &:focus {
        outline:none !important;
    }
    @media (max-width: 1180px) {
        display:block;
    }
`;

const BurgerLines = styled.span`
    display: inline-block;
    cursor: pointer;
    user-select: none;
    transition: all 300ms ease;
    width: 25px;
    height: 3px;
    background-color: #fff;
    position: relative;
    top: -2px;
    border-radius: 1px;
    &::before {
        top: 8px;
        content: '';
        display: inline-block;
        width: 25px;
        height: 3px;
        background-color: #fff;
        position: absolute;
        border-radius: 1px;
        left: 0;
        transform-origin: 50% 50%;
        transition: top 300ms 300ms cubic-bezier(0.165, 0.84, 0.44, 1), transform 300ms cubic-bezier(0.165, 0.84, 0.44, 1), background-color 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
    }
    &::after {
        top: -8px;
        content: '';
        display: inline-block;
        width: 25px;
        height: 3px;
        background-color: #fff;
        position: absolute;
        border-radius: 1px;
        left: 0;
        transform-origin: 50% 50%;
        transition: top 300ms 300ms cubic-bezier(0.165, 0.84, 0.44, 1), transform 300ms cubic-bezier(0.165, 0.84, 0.44, 1), background-color 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
        box-sizing: inherit;
    }
`;

const MobileMenuWrap = styled.div`
    display: ${props => props.display};
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    width: 320px;
    z-index: 1;
    overflow: hidden;
    box-sizing: inherit;
`;

const MobileMenuList = styled.ul`
    padding: 0;
    margin: 0;
    list-style-type: none;
    background: #388D99;
    height: 100%;
`;

const MobileMenuItem = styled.li`
    padding: 0;
    margin: 0;
    list-style-type: none;
`;

const MobileLink = styled(Link)`
    padding: 25px;
    display: block;
    color: #fff;
    text-decoration: none;
    touch-action: manipulation;
    background-color: transparent;
`;

class MobileMenu extends React.Component {
    render() {
        var display = {};
        if (this.props.display !== undefined) {
            display['display'] = this.props.display;
        }
        return (
            <MobileMenuWrap {...display}>
                <MobileMenuList>
                    <MobileMenuItem><MobileLink to="/company">Company</MobileLink></MobileMenuItem>                    
                    <MobileMenuItem><MobileLink to="/technology">Technology</MobileLink></MobileMenuItem>
                    <MobileMenuItem><MobileLink to="/ip">Intellectual Property</MobileLink></MobileMenuItem>
                    <MobileMenuItem><MobileLink to="/publications">Resources</MobileLink></MobileMenuItem>
                    <MobileMenuItem><MobileLink to="/contact">Contact</MobileLink></MobileMenuItem>
                </MobileMenuList>
            </MobileMenuWrap>
        );
    }
}

class Navbar extends React.Component {
    publications = () => {
        this.props.history.push("/publications");
    }
    technology = () => {
        this.props.history.push("/technology");
    }
    ip = () => {
        this.props.history.push("/ip");
    }
    render() {
        var display = {}; 
        if (this.props.display !== undefined) {
            display['display'] = this.props.display;
        }
        var tech_display = {};
        if (this.props.tech_display !== undefined) {
            tech_display['tech_display'] = this.props.tech_display;
        }
        return (
            <Header>
                <Link to="/"><Logo src={logo} /></Link>
                <Nav>
                    <NavItem><NavLink to="/company">company</NavLink></NavItem>
                    <NavItem>
                        <NavLink to="#" onClick={this.props.techDD} onBlur={this.props.techDD}>technology<DropDown /></NavLink>
                        <TDDropDownMenu {...tech_display}>
                            <DropDownLink to="#" onMouseDown={this.technology}>Technology</DropDownLink>
                            <DropDownLink to="#" onMouseDown={this.ip}>Intellectual Property</DropDownLink>
                        </TDDropDownMenu>
                    </NavItem>
                    <NavItem>
                        <NavLink to="#" onClick={this.props.resourcesDD} onBlur={this.props.resourcesDD}>resources<DropDown /></NavLink>
                        <DropDownMenu {...display}>
                            <DropDownLink to="#" onMouseDown={this.publications}>Publications</DropDownLink>
                        </DropDownMenu>
                    </NavItem>
                    <NavItem><NavLink to="/contact">contact</NavLink></NavItem>
                </Nav>
                <BurgerButton onClick={this.props.mobileMenu}>
                    <BurgerLines id="burgerlines" />
                </BurgerButton>
            </Header>
        );
    }
}

class Footer extends React.Component {
    render() {
        return (
            <FooterWrap>
                <FootLogo to="/"><Logo src={logo2} /></FootLogo>
                <FootNav>
                    <FootNavItem><FootNavLink to="/company">company</FootNavLink></FootNavItem>
                    <FootNavItem><FootNavLink to="/technology">technology</FootNavLink></FootNavItem>
                    <FootNavItem><FootNavLink to="/ip">Intellectual Property</FootNavLink></FootNavItem>
                    <FootNavItem><FootNavLink to="/publications">resources</FootNavLink></FootNavItem>
                    <FootNavItem><FootNavLink to="/contact">contact</FootNavLink></FootNavItem>
                </FootNav>
                <Copy>&copy; Copyright 2019 Von Baer Wolff. All Rights Reserved.</Copy>
            </FooterWrap>
        );
    }
}

const Spacer = styled.div`
    margin-bottom:10px;
`;

const Clearfix = styled.div`
    float:none;
    clear:both;
`;

export {Wrapper}
export {Boom}
export {Navbar}
export {MobileMenu}
export {Footer}
export {Spacer}
export {Clearfix}
