import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Boom, Wrapper, Navbar, MobileMenu, Footer } from './Elements';
import bluebg from '../img/blue-bg.png';
import lmap from '../img/map.png';

const ContactHead = styled.div`
    background-image: url(${bluebg});
    background-size: cover;
    background-position: 50% 50%;
    height: 337px;
    padding-top: 60px;
    padding-bottom: 3rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    text-align: left;
`;

const ContactHeadWrapWrap = styled.div`
    width: 100%;
    max-width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    display: block;
`;

const ContactHeadWrap = styled.div`
    -webkit-box-flex: 0;
    flex: 0 0 44%;
    max-width: 44%;
    margin-left:100px;
    padding-right: 15px;
    padding-left: 15px;
    position: relative;
    width: 100%;
    min-height: 1px;
    @media (max-width:887px) {
        flex: 0 0 70%;
        max-width: 70%;
    }
    @media (max-width:600px) {
        margin-left:0px;
    }
`;

const SubHeadline = styled.h2`
    font-size: 14px;
    color: #7AC3FF;
    text-transform: uppercase;
    font-family: "Lato", sans-serif;
    font-weight: 900;
    letter-spacing: 2.75px;
    margin-bottom: 1rem;
    line-height: 1.1;
    margin-top: 0;
`;

const Headline = styled.h1`
    font-size: 33px;
    line-height: 1.4;
    font-family: 'Signika', sans-serif;
    color:#ffffff;
    @media (max-width:600px) {
        font-size: 23px;
    }
`;

const FormHolder = styled.div`
    width:100%;
    max-width: 100%;
    padding-right: 15px;
`;

const ColumnHolder = styled.div`
    padding-left:120px;
    display: flex;
    @media (max-width:860px) {
        padding-left:30px;
    }
    @media (max-width:768px) {
        display:block;
    }
`;

const LeftHolder = styled.div`
    display:inline-block;
    margin-top: 5rem;
    @media (min-width: 768px) {
        -webkit-box-flex: 0;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }
    @media (min-width: 1200px) {
        padding-right: 15px;
        padding-left: 15px;
    }
    @media (max-width:768px) {
        display:block;
    }
    position: relative;
    width: 88%;
    min-height: 1px;
`;

const RightHolder = styled.div`
    display:inline-block;
    @media (min-width: 768px) {
        -webkit-box-flex: 0;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }
    @media (min-width: 1200px) {
        padding-right: 15px;
        padding-left: 15px;
    }
    @media (max-width:768px) {
         display:block;
    }
    position: relative;
    width: 100%;
    min-height: 1px;
`;

const FormHeader = styled.p`
    width: 97%;
    font-family: "Signika", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #242D2D;
    letter-spacing: 0.14px;
    line-height: 1.6;
    margin-bottom: 2rem;
    margin-top: 0;
`;

const Thanks = styled.div`
    margin-bottom:20px;
    width: 97%;
    font-weight:bold;
`;

const NameHolder = styled.div`
    @media (min-width: 768px) {
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%;
    }
    @media (min-width: 1200px) {
        padding-right: 15px;
    }
    position: relative;
    width: 100%;
    min-height: 1px;
`;

const FormLabel = styled.label`
    display: block;
    font-family: "Signika", sans-serif;
    font-weight: 600;
    font-size: 15px;
    color: #00040B;
    letter-spacing: 0.5px;
`;

const FormInput = styled.input`
    height: 44px;
    line-height: 44px;
    width: 100%;
    border: 1px solid rgba(0, 4, 11, 0.31);
    margin-bottom: 2rem;
    border-radius: 2px;
    font-family: "Signika", sans-serif;
    letter-spacing: 0.2px;
    display: block;
    padding: .5rem .75rem;
    font-size: 1rem;
    color: #464a4c;
    background-color: #fff;
    background-image: none;
    background-clip: padding-box;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    overflow: visible;
`;

const EmailHolder = styled.div`
    display:inline-block;
    @media (min-width: 768px) {
        -webkit-box-flex: 0;
        flex: 0 0 45%;
        max-width: 45%;
        float:${props => props.first ? 'left' : 'right'};
    }
    position: relative;
    width: 100%;
    min-height: 1px;
`;

const Comments = styled.textarea`
    float:none;
    clear:both;
    margin-bottom: 0;
    width: 100%;
    border: 1px solid rgba(0, 4, 11, 0.31);
    border-radius: 2px;
    font-family: "Signika", sans-serif;
    letter-spacing: 0.2px;
    display: block;
    padding: .5rem .75rem;
    font-size: 1rem;
    line-height: 1.25;
    color: #464a4c;
    background-color: #fff;
    background-image: none;
    background-clip: padding-box;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    resize: vertical;
    overflow: auto;
    margin: 0;
`;

const ContactInfo = styled.div`
    @media screen and (min-width: 768px) {
        top: -45px;
        right: -40px;
    }
    position: relative;
`;

const LocationImage = styled.div`
    background-image: url(${lmap});
    background-size: cover;
    background-position: 50% 50%;
    @media screen and (min-width: 1200px) {
        height: 407px;
        width: 150%;
    }
    border-radius: 2px;
`;

const InfoBlock = styled.div`
    @media screen and (min-width: 768px) {
        right: -40px;
    }
    margin-top: 4rem;
    position: relative;
`;

const ThatBlock = styled.div`
    margin: 2rem 0;
`;

const SubSection = styled.h3`
    font-family: "Lato", sans-serif;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 14px;
    color: #D80000;
    letter-spacing: 2.75px;
    margin-bottom: 1rem;
    line-height: 1.1;
    margin-top: 0;
`;

const PeopleGettinPaidOff = styled.p`
    font-weight: 700;
    line-height: 1.7;
    margin-bottom: 0;
    font-family: "Signika", sans-serif;
    font-size: 16px;
    color: #242D2D;
    letter-spacing: 0.14px;
`;

const CouldFillATruck = styled(Link)`
    color: inherit;
    position: relative;
    display: inline-block;
    text-decoration: none !important;
    font-family: "Signika", sans-serif;
    touch-action: manipulation;
    background-color: transparent;
    &:after {
        z-index: -1;
        content: "";
        display: block;
        position: absolute;
        bottom: 3px;
        height: 7px;
        width: 100%;
        border-radius: 2px;
        background: rgba(122, 195, 255, 0.28);
    }
`;

const CantHurtUs = styled.a`
    color: inherit;
    position: relative;
    display: block;
    text-decoration: none !important;
    font-family: "Signika", sans-serif;
    touch-action: manipulation; 
    background-color: transparent;
    margin-bottom:7px;
    &:after {
        z-index: -1;
        content: "";
        display: block;
        position: absolute;
        bottom: 3px;
        height: 7px;
        width: 100%;
        border-radius: 2px;
        background: rgba(122, 195, 255, 0.28);
    }
`;

const TheOwls = styled.div`
    margin-bottom:7px;
`;

class Contact extends React.Component {
    state = {
        mobile_display:"none",
        wrapper_margin:"0px",
        resources_ddd:"none",
        tech_ddd:"none",
    }
    mobileMenu = () => {
        var data = this;
        if (data.state.mobile_display === "none") {
            data.setState({
                mobile_display:"block"
            }, () => setTimeout(data.setState({wrapper_margin:"-320px"}), 1000));
        } else {
            data.setState({
                wrapper_margin:"0px"
            }, () => setTimeout(data.setState({mobile_display:"none"}), 1000));
        }
    }
    resourcesDD = () => {
        var data = this;
        if (data.state.resources_ddd === "none") {
            if (data.state.tech_ddd === "block") {
                data.setState({tech_ddd:"none"});
            }
            data.setState({resources_ddd:"block"});
        } else {
            data.setState({resources_ddd:"none"});
        }
    }
    techDD = () => {
        var data = this;
        if (data.state.tech_ddd === "none") {
            if (data.state.resources_ddd === "block") {
                data.setState({resources_ddd:"none"});
            }
            data.setState({tech_ddd:"block"});
        } else {
            data.setState({tech_ddd:"none"});
        }
    }
    render() {
        if (this.state.mobile_display) {
            return (
                <Boom>
                    <MobileMenu display={this.state.mobile_display} />
                    <Wrapper margin={this.state.wrapper_margin}>
                        <Navbar {...this.props} mobileMenu={this.mobileMenu} techDD={this.techDD} tech_display={this.state.tech_ddd} resourcesDD={this.resourcesDD} display={this.state.resources_ddd} />
                        <ContactHead>
                            <ContactHeadWrapWrap>
                                <ContactHeadWrap>
                                    <SubHeadline>Contact Us</SubHeadline>
                                    <Headline>Have questions about our services, our markets or how we might join forces? Get in touch with us.</Headline>
                                </ContactHeadWrap>
                            </ContactHeadWrapWrap>
                        </ContactHead>
                        <FormHolder>
                            <ColumnHolder>
                                <LeftHolder>
                                    <FormHeader>
                                    Von Baer Wolff is creating a hepatocyte supply pipeline for science and industry. Reach out if you're interested in obtaining hepatocytes, curious about ways we might partner, or want to learn about investment opportunities.
                                    </FormHeader>
                                    <Thanks>Thanks for your interest. A representative will get back to you shortly.</Thanks>
                                    <form accept-charset="UTF-8">
                                        <div>
                                            <NameHolder>
                                                <FormLabel>Full Name*</FormLabel>
                                                <FormInput type="text" name="fromName" required />
                                            </NameHolder>
                                        </div>
                                        <div>
                                            <EmailHolder first>
                                                <FormLabel>Email*</FormLabel>
                                                <FormInput type="text" name="fromEmail" required />
                                            </EmailHolder>
                                            <EmailHolder>
                                                <FormLabel>Organization*</FormLabel>
                                                <FormInput type="text" name="fromOrg" required />
                                            </EmailHolder>
                                        </div>
                                        <div style={{clear : 'both'}}>
                                            <NameHolder>
                                                <FormLabel>Message*</FormLabel>
                                                <Comments rows="10" cols="40" name="message" />
                                            </NameHolder>
                                        </div>
				                    </form>
                                </LeftHolder>
                                <RightHolder>
                                    <ContactInfo>
                                        <LocationImage />
                                        <InfoBlock>
                                            <ThatBlock>
                                                <SubSection>Locations</SubSection>
                                                <PeopleGettinPaidOff>Corporate Office</PeopleGettinPaidOff>
                                                <TheOwls>907 Rio Grande Street</TheOwls>
                                                <TheOwls>Austin, TX 78701</TheOwls>
                                                <CouldFillATruck to="https://www.google.com/maps/dir//907+Rio+Grande+St,+Austin,+TX+78701/@34.1501747,-105.6539193,5.83z/data=!4m9!4m8!1m0!1m5!1m1!1s0x8644b50c8e5a18d7:0x16b7dd9d86955149!2m2!1d-97.7479984!2d30.2732661!3e0">Get Directions</CouldFillATruck>
                                            </ThatBlock>
                                            <ThatBlock>
                                                <SubSection>Chief Executive</SubSection>
                                                <PeopleGettinPaidOff>Ronald G. Landes</PeopleGettinPaidOff>
                                                <CantHurtUs src="mailto:rgl@vonbaerwolff.com">rgl@vonbaerwolff.com</CantHurtUs>
                                                <CantHurtUs src="tel:512-508-2321">512-508-2321</CantHurtUs>
                                            </ThatBlock>
                                        </InfoBlock>
                                    </ContactInfo>
                                </RightHolder>
                            </ColumnHolder>
                        </FormHolder>
                        <Footer />
                    </Wrapper>
                </Boom>
            );
        } else {
            return (
                <div>&nbsp;</div>
            );
        }
    }
}

export {Contact}
